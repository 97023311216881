.SelectUserContainer form{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-bottom: 24px;
}

.SelectUserContainer .slim-title{
    margin-bottom: 64px;
}

.SelectUserContainer .inputBox{
    max-width: 400px;
    width: 90%;
    text-align: left;
}

.SelectUserContainer .inputBox p{
    margin-bottom: 4px;
    font-size: 13px;
    width: 100%;
}

.SelectUserContainer #subtext{
    margin-top: 12px;
    font-size: 12px;
    text-align: left;
    max-width: 400px;
}

.css-b62m3t-container {
    font-size: 13px;
}
