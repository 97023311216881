.AccountContainer .inputBox{
    max-width: 400px;
    width: 90%;
    text-align: left;
    margin-bottom: 12px;
}

.AccountContainer .inputBox p{
    margin-bottom: 4px;
    font-size: 13px;
    width: 100%;
}

.AccountContainer div{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.AccountContainer form{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.AccountContainer #subtext{
    margin-top: 12px;
    font-size: 12px;
    text-align: left;
    width: 90%;
    max-width: 400px;
}

.AccountContainer button{
    margin: 24px 0!important;
}

.AccountContainer .radioBox{
    display: flex;
    flex-direction: row;
    font-size: 12px;
}

.AccountContainer .radioBox label{
    padding: 8px;
    background-color: white;
    border: 1px solid var(--main-color);
    border-radius: 6px;
    color: var(--main-color);
}

input[type="radio"]{
    display: none;
}

.activeType{
    background-color: var(--main-color)!important;
    color: white!important;
    font-weight: bold;
}