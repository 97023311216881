.percentageCounter{
    line-height: 16px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 4px;
    background-color: transparent;
    padding: 0;
    cursor: default;
}

#titleLoader{
    position: absolute;
    top: 1px;
    left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
}