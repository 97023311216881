/* RESET CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  font-family: 'Arial', sans-serif;
	vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
}

html, body{
  height: 100%;
  width: 100%;
  margin: 0;
  scroll-behavior: smooth; 
}
/*--------------------*/
/* DEFINE GLOBAL VARS */
:root {
  --main-color-raw: 18, 21, 46;

  --main-color: rgb(var(--main-color-raw));
  --main-color-hover: rgba(var(--main-color-raw), 0.85);
  --filled-text-color: #fff;
  --text-color: #000000;
  --bg: #fff;
  --error: #b30000;
  --success: #329732;
  --disabled: #b0b1b7;
  --border: #b0b1b7;
}

/*--------------------*/

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#root{
  color: var(--text-color);
}

#innerContainer{
  height: 100%;
  display: flex;
  flex-direction: column;
}

#icon{
  margin-right: 6px;
}

p{
  font-size: 12px;
}

hr{
  background-color: var(--main-color);
  height: 1px;
  width: 90%;
  border: none;
}

.react-datepicker__year-dropdown-container{
  margin-top: 4px!important;
}
.react-datepicker__year-read-view--down-arrow{
  right: -20px!important;
}

/* OVERRIDE AUTOFILL STYLE */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
  box-shadow: 0 0 0px 30px var(--main-color) inset;
  -webkit-box-shadow: 0 0 0px 30px var(--main-color) inset;
  -webkit-text-fill-color: var(--filled-text-color);
}
/*--------------------*/
/* DEFINE GLOBAL STYLES */


@media only screen and (max-width: 600px) {
  .slim-background{
    background-size: cover!important;
  }
}

.slim-background {
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient( 180deg, rgb(255 255 255 / 88%) 0%, rgb(255 255 255 / 0%) 100% ), url('./bg.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

.slim-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
}

.slim-button {
  border-radius: 35px;
  padding: 12px 0;
  border: none;
  background-color: var(--main-color);
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  width: 90%;
  max-width: 400px;
  text-decoration: none;
  color: var(--filled-text-color);
  margin-bottom: 32px;
  transition: 0.4s ease;
}

.menu-button {
  margin: 12px 0;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.menu-button svg{
  font-size: 14px;
}

.slim-button:hover{
  background-color: var(--main-color-hover);
}

.disabled{
  background-color: var(--disabled)!important;
  pointer-events: none;
  cursor: default;
}

.hidden{
  opacity: 0;
  pointer-events: none;
  min-height: 0!important;
  max-height: 0!important;
  padding: 0!important;
}

.react-reveal{
  height: 100%;
  width: 100%;
}

input[type="file"] {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.slim-title{
    padding: 12px 32px;
    color: var(--bg);
    font-size: 12px;
    font-weight: 600;
    box-sizing: border-box;
    width: 100%;
    z-index: 9999;
    text-decoration: none;
    background-color: var(--main-color);
    margin: 0 auto;
    cursor: default;
}

.slim-subtitle{
  /* font-style: italic; */
  max-width: 90%;
  padding: 18px 0;
  font-size: 14px;
}

.slim-textinput{
  width: 100%;
  height: 38px;
  padding: 0 12px;
  background-color: var(--main-color);
  color: var(--filled-text-color);
  border: none;
  border-radius: 16px;
  outline: none;
  font-size: 12px;
  box-sizing: border-box;
}

.slim-textinput::placeholder{
  color: rgb(189, 189, 189);
}

.slim-textinput-inline{
  outline: none;
  border: 1px solid var(--border);
  border-radius: 4px;
  margin: 2px 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  padding: 4px;
  cursor: pointer;
}

.slim-notification{
  font-size: 12px;
  border: 1px solid var(--main-color);
  padding: 6px;
  border-radius: 16px;
  margin-top: 20px;
  max-width: 400px;
  text-align: left;
  font-weight: normal;
  display: inline-block;
  background-color: var(--bg);
  width: 90%;
  box-sizing: border-box;
}

.slim-error{
  border: 1px solid var(--error);
  background-color: var(--error);
  color: var(--bg);
  padding: 4px 8px;
  width: auto;
  max-width: 70%;
}

.slim-main{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.slim-progress-bar{
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: 16px 0;
}
.slim-progress-item{
  display: inline-block;
  margin-right: 2px;
  height: 16px;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
}

.slim-form-container{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 90%;
  align-items: center;
}
.slim-form{
  width: 100%;
  max-width: 700px;
  font-size: 12px;
  background-color: var(--bg);
  box-sizing: border-box;
}
.slim-form-row{
  border: 1px solid var(--border);
  border-radius: 4px;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.slim-form-row-row{
  padding: 0 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 35px;
}
.slim-form-row-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slim-form-row-text{
  padding: 10px 4px 10px 0;
  width: 100%;
  text-align: left;
}

.slim-button-inline{
  box-sizing: border-box;
  width: 100%;
  padding: 4px 6px;
  background-color: var(--main-color);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.4s ease;
  white-space: nowrap;
  border: none;
  font-size: 12px;
}
.slim-button-inline:hover{
  background-color: var(--main-color-hover);
}

.slim-checkbox{
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slim-checkbox-input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.slim-checkbox-override{
  position: absolute;
  top: -5px;
  left: 0;
  height: 22px;
  width: 22px;
  border: 1px solid #bbb;
  border-radius: 4px;
  background-color: transparent;
  transition: 0.2s ease;
  transition-delay: 0.1s;
}
.slim-checkbox-override::after {
  content: "";
  position: absolute;
  opacity: 0;
}
.slim-checkbox .slim-checkbox-override::after {
  left: 7px;
  top: 0px;
  width: 5px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transition: 0.2s ease;
}
.slim-checkbox input ~ .slim-checkbox-override::after {
  transform: scale(0.1);
}
.slim-checkbox input:checked ~ .slim-checkbox-override::after {
  transform: rotate(45deg);
  opacity: 1;
  transition-delay: 0.1s;
}
.slim-checkbox input:checked ~ .slim-checkbox-override {
  transition-delay: 0s;
  background-color: #51b551;
}

.positive{
  background-color: #51b551!important;
}
.average{
  background-color: #ff953d!important;
}
.negative{
  background-color: #ff3636!important;
}
.expiring{
  background-color: #ffe266!important
}

.slim-checkbox-placeholder {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--disabled);
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
}
.slim-checkbox-container{
  display: flex;
  white-space: nowrap;
  margin-left: 5px;
}

.no-margin {
  margin-left: 0;
}
.adjust-height {
  margin-bottom: 14px;
}

.slim-textarea{
  margin: 8px 0;
  width: 100%;
  max-width: 700px;
  height: 80px;
  resize: vertical;
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  font-size: 12px;
}
.slim-textarea:focus{
  outline: none;
}

.slim-required{
  color: var(--error);
  font-size: 15px;
}
.slim-required:after{
  content: '*';
}