.HeaderContainer .react-reveal{
    height: auto;
}

#headerBar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg);
    position: relative;
    padding: 12px 24px;
    max-width: 100%;
    z-index: 9999;
}

#headerBar img{
    max-height: 55px;
    max-width: 80%;
}
@media only screen and (max-width: 300px) {
    #headerBar{
        justify-content: center;
    }
    #headerBar img{
        display: none;
    }
}

#headerBar #icon{
    color: var(--main-color);
    font-size: 23px;
    display: block;
    width: 56px;
    margin: 0!important;
}

#underlineHelper{
    position: absolute;
    bottom: 0;
    left: 32px;
    right: 32px;
    margin: auto;
    border-bottom: 2px solid black;
}

.navButtons{
    display: flex;
    flex-direction: row;
}

.navButtons #icon,
.navButtons .hamburger-react{
    transition: 0.4s ease;
}
.navButtons #icon:hover,
.navButtons .hamburger-react:hover{
    color: var(--main-color-hover)!important;
    cursor: pointer;
    transform: scale(1.1);
}
.navButtons .hamburger-react:hover div div{
    background: var(--main-color-hover)!important;
}

.navButtons .hamburger-react div div{
    height: 3px!important;
}

.navButtons a:hover svg{
    color: var(--main-color-hover);
}

.hamburger-react:hover{
    transform: scale(1.1);
}

#navWindow{
    position: absolute;
    width: auto!important;
    right: 0px;
    z-index: 9998;
    margin-top: 38px;
    box-shadow: -1px -1px 10px  #505050;
    -moz-box-shadow: -1px -1px 10px  #505050;
    -webkit-box-shadow: -1px -1px 10px  #505050;
    background-color: var(--bg);
    flex-direction: column;
    border-radius: 0 0 12px 12px;  
    box-sizing: border-box;
    padding: 0px 12px 12px;
}

@media only screen and (max-width: 450px) {
    #navWindow{
        width: 100%!important;
        padding: 0 28px 28px;
    }
}

#navWindow a, #navWindow button{
    padding: 16px;
    margin-right: 20px;
    border: none;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    color: var(--text-color);
    text-align: left;
    transition: 0.4s ease;
}

#navWindow button:hover, #navWindow a:hover{
    color: var(--main-color);
}