.LoginContainer .react-reveal{
    height: auto;
}

.LoginContainer form{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin-top: 64px;
    align-items: center;
}

.LoginContainer form span{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
    background-color: var(--main-color);
    margin-bottom: 20px;
    border-radius: 16px;
    width: 90%;
}

.LoginContainer form .icon{
    width: 40px;
    padding: 0 12px;
    font-size: 14px;
    color: var(--filled-text-color);
}

.LoginContainer form input{
    width: 100%;
    height: 100%;
    padding: 0 12px;
    background-color: transparent;
    border: none;
    border-radius: 0 16px 16px 0;
    outline: none;
    font-size: 12px;
    color: var(--filled-text-color);
    border-left: 2px solid var(--bg);
}

.LoginContainer form input::placeholder{
    color: rgba(255, 255, 255, 0.5);
}

.LoginContainer button{
    margin-bottom: 32px;
}

.LoginContainer a{
    color: var(--filled-text-color);
    text-decoration: none;
    padding: 4px 12px;
    border-radius: 8px;
    background-color: var(--main-color);
    font-size: 11px;
}
.LoginContainer a:hover{
    background-color: var(--main-color-hover);
}