.LoggedOutContainer{
    justify-content: space-between;
}

.LoggedOutContainer p{
    font-size: 18px;
}

.LoggedOutContainer img{
    margin: 40px 0 18px;
    width: 400px;
    max-width: 90%;
    max-height: 500px;
}

.LoggedOutContainer div{
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.LoggedOutContainer a{
    width: 90%;
}