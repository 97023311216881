#loadingBackground{
    position: absolute;
    overflow: hidden;
    top: 0;
    min-width: 100%;
    min-height: 100%;
}

.loadingDiv{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.loadingDiv h1{
    color: var(--main-color);
    font-size: 28px;
    padding: 28px;
    border-radius: 12px;
    display: inline-block;
}