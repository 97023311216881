.InformationContainer .inputBox{
    max-width: 400px;
    width: 90%;
    text-align: left;
    margin-bottom: 12px;
}

.InformationContainer .inputBox p{
    margin-bottom: 4px;
    font-size: 13px;
    width: 100%;
}

.InformationContainer div{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.InformationContainer form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.InformationContainer #logoUpload{
    width: auto;
    max-width: 400px;
    background-color: var(--main-color);
    color: var(--filled-text-color);
    border-radius: 16px;
    padding: 4px;
    margin: 0 0 8px;
    box-sizing: border-box;
}

.InformationContainer #logoUpload img{
    box-sizing: border-box;
    max-width: 100%;
    background-color: var(--bg);
    border-radius: 12px;
    padding: 8px;
    min-width: 30%;
}

.InformationContainer label{
    width: 70%;
    max-width: 400px;
    background-color: var(--main-color);
    color: var(--filled-text-color);
    border-radius: 16px;
    padding: 6px 12px;
    box-sizing: border-box;
    text-align: center;
    font-size: 13px;
}

.InformationContainer label:hover{
    background-color: var(--main-color-hover);
    cursor: pointer;
}

.InformationContainer #subtext{
    font-size: 12px;
    width: 70%;
    max-width: 400px;
    margin-top: 4px;
}

.InformationContainer input::placeholder{
    color: rgba(255, 255, 255, 0.5);
}