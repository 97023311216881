.SelectionBox{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
    border-bottom: none;
    height: 100%;
}

.SelectionBox a{
    padding: 12px;
    width: 90%;
    max-width: 400px;
    margin: 12px 0;
    box-sizing: border-box;
    background-color: var(--main-color);
    color: var(--main-color);
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    transition: 0.4s ease;
    border-radius: 8px;
}

.SelectionBox a:hover{
    cursor: pointer;
    background-color: var(--main-color-hover);
}

.SelectionBox .title{
    color: var(--main-color);
    background-color: var(--bg);
    border-radius: 12px;
    padding: 6px 18px;
    display: inline-block;
    margin: auto;
    width: 80%;
    font-weight: bold;
    box-sizing: border-box;
}

.SelectionBox p{
    color: var(--bg);
    font-weight: initial;
}

.SelectionBox .trajectInformation{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.SelectionBox .trajectInformation .dateBox{
    display: flex;
    flex-direction: column;
    padding: 8px;
    box-sizing: border-box;
    text-align: left;
}

.CircularProgressbar{
    height: 50px;
    width: auto!important;
}

.react-reveal{
    height: auto!important;
}